<!--
 * @Author: 月魂
 * @Date: 2022-09-08 11:13:29
 * @LastEditTime: 2022-09-20 16:37:44
 * @LastEditors: 月魂
 * @Description: 单个活动内容列表
 * @FilePath: \silk-road\src\views\activities\activity-list.vue
-->
<template>
  <div class="box">
    <div class="header">
      <img src="http://image.wearetogether.com.cn/8th/assets/list/activity-list.png" alt="" class="header-img">
      <div class="header-text">
        <van-icon name="arrow-left" class="back" @click="handleBack" />
        <!-- <div class="title">{{ getItem.title }}</div> -->
        <!-- <div class="enTitle" v-if="getItem.enTitle">{{ getItem.enTitle }}</div> -->
      </div>
    </div>
    <!-- 活动列表 -->
    <div class="list">
      <div class="item" @click="jump(act)" v-for="act in getItem.list" :key="act.id">
        <img class="top-img" :src="act.imgUrl" alt="" width="100%">
        <div class="bottom">
          <div class="title">{{ act.title }}</div>
          <div class="team">{{ act.host }}</div>
          <div class="time" v-show="act.time">{{ act.time }}</div>
          <div class="address">{{ act.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'vant'
import { handleBack } from '../../utils/util'
Vue.use(Icon)

export default {
  name: 'ActivityList',
  components: {
  },
  data() {
    return {
      activity: {}
    }
  },
  computed: {
    getItem: function() {
      let item = {};
      const index = Object.values(this.$i18n.t('activities.list')).findIndex(item => item.id == this.$route.params.id)
        item = Object.values(this.$i18n.t('activities.list'))[index]
      return item
    }
  },
  mounted() {
    this.wxShareDef();
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(item) { // 此处要根据活动内容判断进入相应的界面
      // 如果有list则进入列表页，如果有performList则进入指定剧目列表页，否则进入详情页
      if (item.performList) {
        this.$router.push({ path: '/specify-list', query: { title: item.title, enTitle: item.enTitle, playType: item.playType } })
      } else if (item.cont) {
        if (item.type) {
          this.$router.push(`/${item.type}-detail/${item.linkId}`)
        } else {
          this.$router.push(`/activity-detail/${item.id}`)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  .header {
    max-width: 1200PX;
    margin: auto;
    color: #fff;
    word-break: break-word;
    position: relative;
    .header-img {
      display: block;
      width: 100%;
    }
    .header-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      .back {
        position: absolute;
        top: 8px;
        left: 16px;
        width: 16px;
        font-size: 16px;
      }
      .title {
        font-family: SourceHanSerifCN-Bold;
        font-size: 34px;
        color: #FFF6E2;
        position: absolute;
        top: 24px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
      }
      .enTitle {
        font-family: Arial;
        text-align: center;
        font-size: 12px;
        color: #FFF6E3;
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
  .list {
    max-width: 1200PX;
    margin: auto;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      background-color: #fff;
      margin-top: 16px;
      border-radius: 10px;
      overflow: hidden;
      &:first-child {
        margin-top: 0;
      }
      .top-img {
        display: block;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
      }
      .bottom {
        padding: 14px 16px;
        display: flex;
        flex-wrap: wrap;
        font-family: SourceHanSansCN-Medium;
        .title {
          width: 100%;
          font-size: 18px;
          color: #4D4D4D;
        }
        .team {
          width: 100%;
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
        .time {
          font-size: 12px;
          margin-right: 8px;
          color: #999999;
          margin-top: 8px;
        }
        .address {
          font-size: 12px;
          color: #999999;
          margin-top: 8px;
        }
      }
    }
    @media (min-width: 1200PX) {
      padding: 0;
      margin-top: 10px;
      .item {
        width: 49%;
      }
    }
  }
}
</style>
